<template>
  <div>
    <span class="material-input-bar" />
    <label class="material-label">
      <slot />
    </label>
    <br v-if="$slots['default']" />
    <div v-for="language in languages" :key="language.code">
      <div style="display: inline-block; width: 20%">
        <span>{{ language.description }}</span>
      </div>
      <div style="display: inline-block; width: 80%">
        <MDinput
          v-if="type === 'currency'"
          :key="language.code"
          v-model="currentValue[language.code]"
          type="currency"
          :loading="languagesLoading"
          v-bind="$attrs"
          :placeholder="language.description"
          :translate-placeholder="false"
          @input="handleInput($event, language)"
        />
        <MDinput
          v-if="type === 'text'"
          :key="language.code"
          v-model="currentValue[language.code]"
          :loading="languagesLoading"
          v-bind="$attrs"
          :placeholder="language.description"
          :translate-placeholder="false"
          @input="handleInput($event, language)"
        />
        <TextArea
          v-if="type === 'textarea'"
          :key="language.code"
          v-model="currentValue[language.code]"
          :loading="languagesLoading"
          v-bind="$attrs"
          :placeholder="language.description"
          :translate-placeholder="false"
          @input="handleInput($event, language)"
        />
        <Select
          v-if="type === 'select'"
          :key="language.code"
          v-model="currentValue[language.code]"
          :loading="languagesLoading"
          v-bind="$attrs"
          :placeholder="language.description"
          :translate-placeholder="false"
          :options="options"
          @input="handleInput($event, language)"
        />
        <InputNumber
          v-if="type === 'number'"
          :key="language.code"
          v-model="currentValue[language.code]"
          :loading="languagesLoading"
          v-bind="$attrs"
          :placeholder="language.description"
          :translate-placeholder="false"
          :min="min"
          :max="max"
          :precision="precision"
          :step="step"
          @input="handleInput($event, language)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import MDinput from '@/components/MDinput';
import TextArea from '@/components/DSE/TextArea';
import InputNumber from '@/components/DSE/InputNumber';
import Select from './Select';

export default {
  components: {
    Select,
    MDinput,
    TextArea,
    InputNumber
  },
  props: {
    name: String,
    value: {
      default() {
        return {};
      },
      type: [Object, String]
    },
    type: {
      type: String,
      default: 'text'
    },
    readonly: Boolean,
    disabled: Boolean,
    required: {
      type: Boolean,
      default: true
    },
    languageCodes: {
      type: Array,
      default() {
        return null;
      }
    },
    isNumeric: {
      type: Boolean,
      default: false
    },
    min: Number,
    max: Number,
    precision: Number,
    step: Number,
    options: [Array]
  },
  data() {
    return {
      currentValue: {}
    };
  },
  computed: {
    languages() {
      let languages = this.$store.getters['language/loadedItems'];
      if (this.languageCodes) {
        languages = languages.filter(language => this.languageCodes.find(code => code === language.code));
      }
      return languages;
    },
    languagesLoading() {
      return this.$store.getters['language/isLoading'];
    }
  },
  watch: {
    value(newValue) {
      this.currentValue = [];
      if (newValue && newValue.translations) {
        newValue.translations.forEach(translation => {
          if (this.isNumeric) {
            this.currentValue[translation.languageCode] = translation.number;
          } else {
            this.currentValue[translation.languageCode] = translation.text;
          }
        });
      }
    }
  },
  methods: {
    handleInput(input, language) {
      const newValue = {
        ...this.value
      };
      const translation =
        newValue && newValue.translations && newValue.translations.find(t => t.languageCode === language.code);
      if (translation) {
        if (this.isNumeric) {
          translation.number = input;
        } else {
          translation.text = input;
        }
      } else {
        if (newValue.translations === undefined) {
          newValue['translations'] = [];
        }

        if (this.isNumeric) {
          newValue.translations.push({
            languageCode: language.code,
            languageID: language.id,
            number: input
          });
        } else {
          newValue.translations.push({
            languageCode: language.code,
            languageID: language.id,
            text: input
          });
        }
      }
      this.$emit('input', newValue);
    }
  }
};
</script>
