<template>
  <div>
    <el-upload
      v-if="!disabled"
      :action="uploadAction"
      drag
      :multiple="false"
      :show-file-list="false"
      auto-upload
      :headers="headers"
      name="image"
      :on-error="handleError"
      :on-change="handleChange"
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text" v-html="$t('fileUpload.dragAndDropHtml')"></div>
      <div slot="tip" class="el-upload__tip">{{ $t('fileUpload.uploadTip') }}</div>
    </el-upload>
    <div>
      <img v-if="imageUrl" :src="imageUrl" />
      <app-file
        v-if="currentValue"
        :disabled="disabled"
        :file="currentValue"
        :download-action="downloadAction"
        :delete-action="deleteAction"
        @remove="handleRemove"
      />
    </div>
  </div>
</template>

<script>
import AppFile from './AppFile';
import { getToken } from '@/utils/auth';
import { baseURL } from '@/utils/request';

export default {
  components: {
    AppFile
  },
  props: {
    name: String,
    value: String,
    disabled: {
      type: Boolean,
      default: false
    },
    uploadRoute: {
      type: String
    },
    downloadAction: {
      type: String
    },
    deleteAction: {
      type: String
    },
    isImageYN: {
      type: Boolean
    }
  },
  data() {
    return {
      headers: { Authorization: 'Bearer ' + getToken() },
      currentValue: null,
      imageUrl: null
    };
  },
  computed: {
    entityID() {
      return this.$route.params && this.$route.params.id;
    },
    uploadAction() {
      return `${baseURL}/${this.uploadRoute}/${this.entityID}`;
    }
  },
  watch: {
    value(fileName) {
      if (fileName) {
        this.currentValue = {
          name: fileName,
          percentage: 100,
          status: 'success'
        };
        if (this.isImageYN) {
          this.$store.dispatch('product/downloadImage', this.entityID).then(imageDownloaded => {
            this.imageUrl = URL.createObjectURL(imageDownloaded.data);
          });
        }
      }
    }
  },
  methods: {
    handleChange(file, fileList) {
      if (file && file.status === 'success') {
        this.currentValue = file;
        if (this.isImageYN) {
          this.$store.dispatch('product/downloadImage', this.entityID).then(imageDownloaded => {
            this.imageUrl = URL.createObjectURL(imageDownloaded.data);
          });
        }
      }
    },
    handleError(error) {
      this.$store.dispatch('notify/error', error);
    },
    handleRemove(file) {
      this.currentValue = null;
      this.imageUrl = null;
    }
  }
};
</script>
