<template>
  <div>
    <span class="material-input-bar" />
    <label class="material-label">
      <slot />
    </label>
    <br v-if="$slots['default']" />
    <el-input-number
      ref="input"
      v-model="currentValue"
      :readonly="readonly"
      :required="required"
      :disabled="disabled"
      :precision="precision"
      :step="step"
      :max="max"
      :min="min"
      :controls-position="controlsPosition"
      @change="handleModelInput"
    >
    </el-input-number>
  </div>
</template>
<script>
export default {
  props: {
    name: String,
    value: {
      type: Number,
      default: undefined
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: true
    },
    precision: {
      type: Number,
      default: 0
    },
    step: {
      type: Number,
      default: 1
    },
    max: {
      type: Number,
      default: 32768
    },
    min: {
      type: Number,
      default: 0
    },
    controlsPosition: {
      type: String
    }
  },
  data() {
    return {
      currentValue: this.value
    };
  },
  watch: {
    value(newValue) {
      this.currentValue = newValue;
      if (newValue < this.min) {
        this.handleModelInput(this.min);
      } else if (newValue > this.max) {
        this.handleModelInput(this.max);
      }
    }
  },
  methods: {
    handleModelInput(value) {
      this.$emit('input', value);
      this.$emit('change', value);
    },
    focus() {
      this.$refs.input.select();
    }
  }
};
</script>
