import MDinput from '@/components/MDinput';
import { validatorRequire, validatorConditionalRequired, validatorConditionalRequiredNumber } from '@/utils/validators';
import Switch from '@/components/DSE/Switch';
import FileUpload from '@/components/DSE/FileUpload';
import MultiLanguageInput from '@/components/DSE/MultiLanguageInput';
import Select from '@/components/DSE/Select';
import PickRec from '@/components/DSE/PickRec';
import DateTimePicker from '@/components/DSE/DateTimePicker';
import CrudDiscountRule from '../components/CrudDiscountRule';
import TextArea from '../../../components/DSE/TextArea';

export function getFormDefinition(vue) {
  return {
    type: 'tab',
    saveButtons: [true, false, false, false, false], // Page, tab 1, tab 2, ..
    showFormLabels: [true, true, true, true], // Tab 1, tab 2, ....
    labels: ['common.product', 'common.translations', 'common.images', 'common.discountRules'],
    headerTemplate: (str, form) => `
      <h3>
      ${form.name || ''}
      </h3>
      `,
    groups: [
      // tab 1
      {
        name: {
          type: MDinput,
          defaultValue: '',
          props: {
            maxlength: 64,
            required: true
          },
          rules: [{ validator: validatorRequire }]
        },
        shortDescription: {
          type: MDinput,
          defaultValue: '',
          props: {
            maxlength: 100,
            required: false,
            editable: false
          }
        },
        unit: {
          type: MDinput,
          defaultValue: '',
          props: {
            caption: 'common.unit',
            maxlength: 64
          }
        },
        snelStartID: {
          type: MDinput,
          props: {
            caption: 'common.itemNumber',
            editable: false,
            required: true,
            max: maxNumber
          },
          rules: [{ validator: validatorRequire }]
        },
        productRegistrationGroupID: {
          type: Select,
          props: {
            caption: 'common.productRegistrationGroup',
            valueField: 'id',
            labelField: 'name',
            loading: vue.$store.getters['productRegistrationGroup/isLoading'],
            options: vue.$store.getters['productRegistrationGroup/loadedItems']
          },
          dynamicProps: form => ({
            required: form.publishToWebshopYN
          }),
          dynamicRules: form => [{ validator: validatorConditionalRequired(form.publishToWebshopYN) }]
        },
        productCategoryID: {
          type: Select,
          props: {
            caption: 'common.productCategory',
            valueField: 'id',
            labelField: 'name',
            loading: vue.$store.getters['productCategory/isLoading'],
            options: vue.$store.getters['productCategory/loadedItems']
          },
          dynamicProps: form => ({
            required: form.publishToWebshopYN
          }),
          dynamicRules: form => [{ validator: validatorConditionalRequired(form.publishToWebshopYN) }]
        },
        publishToWebshopYN: {
          type: Switch,
          defaultValue: false,
          props: {
            activeText: 'common.yes',
            inactiveText: 'common.no'
          }
        },
        outOfStock: {
          type: Switch,
          defaultValue: false,
          props: {
            caption: 'product.outOfStock',
            activeText: 'common.yes',
            inactiveText: 'common.no'
          }
        },
        inStockExpectedDateTime: {
          type: DateTimePicker,
          props: {
            caption: 'common.inStockExpectedDateTime',
            type: 'datetime',
            format: vue.$store.getters.dateTimeFormat
          },
          dynamicProps: form => ({
            visible: form.outOfStock,
            required: form.outOfStock
          }),
          valueOnFormChange: (form, oldValue) => {
            if (!form.outOfStock) {
              return null;
            }
            return oldValue;
          },
          dynamicRules: form => [{ validator: validatorConditionalRequired(form.outOfStock) }]
        },
        hasVariantsYN: {
          type: Switch,
          defaultValue: false,
          props: {
            caption: 'product.hasVariants',
            activeText: 'common.yes',
            inactiveText: 'common.no'
          }
        },
        productAttributeID: {
          type: Select,
          props: {
            caption: 'common.productAttribute',
            valueField: 'id',
            labelField: 'name',
            loading: vue.$store.getters['productAttribute/isLoading'],
            options: vue.$store.getters['productAttribute/loadedItems']
          },
          dynamicProps: form => ({
            visible: form.hasVariantsYN,
            required: form.hasVariantsYN
          }),
          valueOnFormChange: (form, oldValue) => {
            if (!form.hasVariantsYN) {
              return null;
            }
            return oldValue;
          },
          dynamicRules: form => [{ validator: validatorConditionalRequiredNumber(form.hasVariantsYN) }]
        },
        productVariantParentID: {
          type: PickRec,
          defaultValue: null,
          props: {
            caption: 'product.variant',
            valueField: 'id',
            labelField: 'name',
            inputField: 'name',
            maxlength: 64,
            columns: [{ field: 'name', label: 'common.name' }],
            entity: 'Product',
            usePagination: true,
            hideInput: false,
            suggest: true
          },
          dynamicProps: form => ({
            visible: !form.hasVariantsYN
          }),
          valueOnFormChange: (form, oldValue) => {
            if (form.hasVariantsYN) {
              return null;
            }
            return oldValue;
          }
        },
        productAttributeItemID: {
          type: Select,
          props: {
            caption: 'common.productAttributeItem',
            valueField: 'id',
            labelField: 'name',
            loading: vue.$store.getters['productAttributeItem/isLoading']
          },
          dynamicProps: form => ({
            visible: !form.hasVariantsYN && !!form.productVariantParentID,
            options: vue.$store.getters['productAttributeItem/loadedItems'].filter(e =>
              e.products.some(p => p === form.productVariantParentID)
            )
          }),
          valueOnFormChange: (form, oldValue) => {
            if (form.hasVariantsYN || !form.productVariantParentID) {
              return null;
            }
            return oldValue;
          },
          dynamicRules: form => [
            { validator: validatorConditionalRequiredNumber(form.productVariantParentID !== null) }
          ]
        },
        rawPrice: {
          type: MultiLanguageInput,
          props: {
            type: 'currency',
            isNumeric: true
          },
          dynamicProps: form => ({
            required: form.publishToWebshopYN,
            validator: validatorRequire
          }),
          dynamicRules: form => [{ validator: validatorConditionalRequired(form.publishToWebshopYN) }]
        },
        vatPercentage: {
          type: MultiLanguageInput,
          props: {
            caption: 'common.vatPercentage',
            type: 'select',
            options: [
              { id: 'reduced-rate', value: 'Laag' },
              { id: 'standard', value: 'Hoog' },
              { id: 'zero-rate', value: 'Geen' }
            ]
          },
          dynamicProps: form => ({
            required: form.publishToWebshopYN
          }),
          dynamicRules: form => [{ validator: validatorConditionalRequired(form.publishToWebshopYN) }]
        },
        hasDepositClassYN: {
          type: Switch,
          defaultValue: false,
          props: {
            caption: 'product.hasDeposit',
            activeText: 'common.yes',
            inactiveText: 'common.no'
          }
        },
        depositClassID: {
          type: Select,
          props: {
            caption: 'common.depositClass',
            valueField: 'id',
            labelField: 'name',
            loading: vue.$store.getters['depositClass/isLoading'],
            options: vue.$store.getters['depositClass/loadedItems']
          },
          dynamicProps: form => ({
            visible: form.hasDepositClassYN
          }),
          valueOnFormChange: (form, oldValue) => {
            if (!form.hasDepositClassYN) {
              return null;
            }
            return oldValue;
          }
        },
        syncError: {
          type: TextArea,
          defaultValue: '',
          props: {
            disabled: true
          }
        }
      },
      // tab 2 translations
      {
        fullDescription: {
          type: MultiLanguageInput,
          props: {
            type: 'textarea',
            caption: 'common.fullDescription'
          }
        }
      },
      // tab 3 images
      {
        imageFileName: {
          type: FileUpload,
          defaultValue: null,
          hideOnCreate: true,
          props: {
            caption: 'product.image',
            uploadRoute: 'product/image',
            deleteAction: 'product/deleteImage',
            downloadAction: 'product/downloadImage',
            isImageYN: true
          }
        },
        productInfoFileName: {
          type: FileUpload,
          defaultValue: null,
          hideOnCreate: true,
          props: {
            caption: 'product.productinfo',
            uploadRoute: 'product/productinfo',
            deleteAction: 'product/deleteProductInfo',
            downloadAction: 'product/downloadProductInfo',
            isImageYN: false
          }
        }
      },
      // tab 4
      {
        discountRules: {
          type: CrudDiscountRule,
          props: {
            openInFullscreen: true,
            parentID: 'productID',
            parentIdFilterField: 'productID',
            permissionCode: 'DiscountRule'
          }
        }
      }
    ]
  };
}

const maxNumber = 999999999999999;

const layoutFullWidht = {
  sections: [
    {
      width: 100
    }
  ]
};

export function getCreateLayoutDefinition(vue) {
  return {
    groups: [layoutFullWidht]
  };
}

export function getEditLayoutDefinition(vue) {
  return {
    groups: [layoutFullWidht, layoutFullWidht, layoutFullWidht, layoutFullWidht]
  };
}
export default getFormDefinition;

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  return item;
}
