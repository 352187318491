<template>
  <div class="datePicker">
    <span class="material-input-bar" />
    <label class="material-label">
      <slot />
    </label>
    <br v-if="$slots['default']" />
    <el-date-picker
      v-if="!disabled"
      ref="datepicker"
      v-model="currentValue"
      :type="type"
      :format="format"
      :name="name"
      :placeholder="fillPlaceHolder"
      :readonly="readonly"
      :required="required"
      :picker-options="weekPickerOptions"
      @input="handleModelInput"
    />
    <span v-if="disabled" class="datePicker-disabled">{{ valueString }}</span>
  </div>
</template>

<script>
import { formatDateByElementFormat } from '@/utils/date';
export default {
  props: {
    disabled: Boolean,
    format: {
      default: 'dd-MM-yyyy HH:mm',
      type: String
    },
    name: String,
    placeholder: {
      default: '',
      type: String
    },
    readonly: Boolean,
    required: {
      default: true,
      type: Boolean
    },
    value: [String, Number],
    type: {
      type: String,
      default: 'datetime'
    }
  },
  data() {
    return {
      currentValue: this.value
    };
  },
  computed: {
    fillPlaceHolder() {
      return this.currentValue ? '' : this.placeholder;
    },
    valueString() {
      const dt = new Date(this.currentValue).toString();
      return this.currentValue && formatDateByElementFormat(dt, this.format);
    },
    weekPickerOptions() {
      return {
        firstDayOfWeek: 1
      };
    }
  },
  watch: {
    value(newValue) {
      this.currentValue = newValue;
      if (newValue && typeof newValue === 'string') {
        const parsedValue = this.handleModelInput(newValue);
        this.$emit('init', parsedValue);
      }
    }
  },
  methods: {
    setSoftFocus() {
      if (this.$refs.datepicker) {
        this.$refs.datepicker.focus();

        this.$nextTick(() => {
          this.$refs.datepicker.hidePicker();
        });
      }
    },
    setFocus() {
      if (this.$refs.datepicker) {
        this.$refs.datepicker.focus();
      }
    },
    focus() {
      if (this.value) {
        this.setSoftFocus();
      } else {
        this.setFocus();
      }
    },
    handleModelInput(event) {
      const value = event && Date.parse(event);
      this.$emit('input', value);
      this.$emit('change', value);
      return value;
    }
  }
};
</script>

<style scoped>
.datePicker-disabled {
  margin-top: 5px;
  display: block;
}
</style>

<style>
.material-label {
  color: #9e9e9e;
}
</style>
